// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import { NotificationPropType } from '../lib/PropTypes';
import NotificationDate from '../NotificationDate';

const DateColumn = ({ notification, onSelectNotification }) => (
    <NotificationDate
        notification={notification}
        className="list-context-actions"
        onClick={() => onSelectNotification({ notification })}
    />
);

DateColumn.propTypes = {
    notification: NotificationPropType.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
};

export default DateColumn;
