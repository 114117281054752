// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { readLocalStorageValue } from 'common/helpers/readLocalStorageValue';
import { ODBC_DEFAULT_LIST_INPUT } from 'components/pages/OdbcPage/defaultListInput';
import { odbcAddRoute } from 'routes/odbcAddRoute';
import { odbcEditRoute } from 'routes/odbcEditRoute';
import apolloClient from '../apolloClient';

import ODBC_DOMAIN_LIST from 'queries/OdbcDomainList.graphql';
import ODBC_DOMAIN_PAGE from 'queries/OdbcDomainPage.graphql';

export const odbcDomainRoute = {
    path: '/cp/domains/:domainId/odbc',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/OdbcPage/OdbcDomainPage" */
        'components/pages/OdbcPage/OdbcDomainPage'
    ),
    loader: async ({ params }) => {
        const input = readLocalStorageValue(`odbcDomainPageListInput${params.domainId}`, ODBC_DEFAULT_LIST_INPUT);
        const id = btoa(`Domain:${params.domainId}`);

        const [{ data: domainData }] = await Promise.all([
            apolloClient.query({
                query: ODBC_DOMAIN_PAGE,
                variables: { id },
            }),
            apolloClient.query({
                query: ODBC_DOMAIN_LIST,
                variables: { id, input },
            }),
        ]);

        return domainData;
    },
    children: [
        odbcAddRoute,
        odbcEditRoute,
    ],
};
