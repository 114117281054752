// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import Popup from './Popup';
import { LEFT_ARROW, RIGHT_ARROW } from './keyCode';
import ce from './createElement';
import render from './render';

import './PopupSlider.less';

class PopupSlider extends Popup {
    _initConfiguration(config) {
        super._initConfiguration({
            popupCls: 'popup-panel popup-panel-slider popup-panel-auto',
            closeButtonEnabled: true,
            hideOnEscape: true,
            ...config,
        });

        this._slides = this._getConfigParam('slides', []);
        this._current = this._getConfigParam('current', 0);
    }

    _initComponentElement() {
        super._initComponentElement();
        this.setCurrent(this._current);
    }

    _getContentArea() {
        return ce('.slider',
            ce('ul.slider__container',
                this._slides.map(() => ce('li.slider__item')),
            ),
            this._slides.length > 1 ? ce('.slider__arrows',
                ce('.slider__arrow.slider__arrow--start',
                    { onclick: () => this.prev() },
                    '<svg class="slider__arrow-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 40" width="20" height="40">' +
                          '<polyline points="16,4 4,20 16,36" fill="none" stroke="currentColor" stroke-width="4"' +
                          ' stroke-linejoin="round" stroke-linecap="round" />' +
                     '</svg>',
                ),
                ce('.slider__arrow.slider__arrow--end',
                    { onclick: () => this.next() },
                    '<svg class="slider__arrow-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 40" width="20" height="40">' +
                        '<polyline points="16,4 4,20 16,36" fill="none" stroke="currentColor" stroke-width="4"' +
                        ' stroke-linejoin="round" stroke-linecap="round" />' +
                    '</svg>',
                ),
            ) : null,
            ce('.slider__navigation',
                ce('.slider__navigation-inner',
                    this._slides.map((item, index) => ce('span.slider__navigation-dot', { onclick: () => this.setCurrent(index) })),
                ),
            ),
        );
    }

    _onKeyDown(e) {
        super._onKeyDown(e);
        switch (e.keyCode) {
            case LEFT_ARROW:
                this.prev();
                break;
            case RIGHT_ARROW:
                this.next();
                break;
        }
    }

    setCurrent(current) {
        this.renderSlide(current);

        const firstItem = this._componentElement.querySelector('.slider__item');
        const dots = this._componentElement.querySelectorAll('.slider__navigation-dot');

        dots.item(this._current).classList.remove('slider__navigation-dot--current');
        this._current = current;
        firstItem.style.marginLeft = `${-100 * current}%`;
        dots.item(this._current).classList.add('slider__navigation-dot--current');
    }

    renderSlide(current) {
        const curItem = this._componentElement.querySelector(`.slider__item:nth-child(${current + 1})`);
        if (curItem.innerHTML.length === 0) {
            render(curItem, this._slides[current]);
        }
    }

    next() {
        this.setCurrent(this._current === this._slides.length - 1 ? 0 : this._current + 1);
    }

    prev() {
        this.setCurrent(this._current === 0 ? this._slides.length - 1 : this._current - 1);
    }
}

export default PopupSlider;
