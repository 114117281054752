// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { parsePath } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from '@plesk/ui-library';
import { prepareUrl } from 'jsw';
import { useLocalStorage } from 'common/hooks';
import ViewSwitcher from './ViewSwitcher';
import Brand from '../Brand';
import Link from 'components/common/Link';

const Node = ({
    id,
    styleClass,
    active,
    disabled,
    name,
    conhelp,
    link,
    target,
    custom,
    icon,
    onClick,
}) => (
    <Tooltip title={conhelp}>
        <li
            id={id || null}
            className={classNames(!id && styleClass, active && 'active', disabled && 'page-sidebar-menu-item--disabled')}
        >
            <Link
                to={prepareUrl(link)}
                target={target}
                onClick={onClick}
                disabled={disabled}
            >
                <i
                    className={styleClass ? `icon-${styleClass.split(' ')[0]}` : null}
                    style={custom ? { backgroundImage: `url('${icon}')` } : null}
                />
                <span className="title">{name}</span>
            </Link>
        </li>
    </Tooltip>
);

Node.propTypes = {
    id: PropTypes.string,
    styleClass: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    conhelp: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    target: PropTypes.string,
    custom: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
};

Node.defaultProps = {
    id: undefined,
    styleClass: undefined,
    active: false,
    disabled: false,
    target: undefined,
    custom: false,
    icon: undefined,
    onClick: undefined,
};

const Group = ({ id, name, nodes, activeNode, isSidebarClosed, onNodeClick }) => {
    const nodeRef = useRef(null);
    const group = id.toString().replace('_', '-');
    const [isHidden, setHidden] = useLocalStorage(`menu-group-${group}-hidden`);

    let title = (
        <button
            className="menu-group-title"
            aria-expanded={isHidden === 'true' ? 'false' : 'true'}
            aria-controls={`menuId-${group}`}
            onClick={() => setHidden(isHidden === 'true' ? 'false' : 'true')}
            type="button"
        >
            <span className="menu-group-toggle" />
            <span className="menu-group-name">{name}</span>
        </button>
    );

    if (isSidebarClosed && name) {
        title = (
            <Tooltip title={name}>
                {title}
            </Tooltip>
        );
    }

    return (
        <li key={id} className={`menu-group-${group}`}>
            {id === 'general' ? null : title}
            {nodes && Object.keys(nodes).length ? (
                <Transition nodeRef={nodeRef} in={isHidden !== 'true'} timeout={200} mountOnEnter unmountOnExit>
                    <ul ref={nodeRef} className="sub-menu" id={`menuId-${group}`}>
                        {Object.entries(nodes).map(([key, { conhelp, ...props }]) => (
                            <Node
                                key={key}
                                active={key === activeNode}
                                conhelp={isSidebarClosed ? props.name : conhelp}
                                onClick={onNodeClick}
                                {...props}
                            />
                        ))}
                    </ul>
                </Transition>
            ) : null}
        </li>
    );
};

Group.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    nodes: PropTypes.object.isRequired,
    activeNode: PropTypes.string,
    isSidebarClosed: PropTypes.bool.isRequired,
    onNodeClick: PropTypes.func,
};

Group.defaultProps = {
    activeNode: undefined,
    onNodeClick: null,
    name: null,
};

const getFirstLink = navigation => {
    for (const group of navigation) {
        const foundNode = Object.values(group.nodes).find(node => node.link);

        if (foundNode) {
            return foundNode.link;
        }
    }
};

const PageSidebar = ({ title, navigation, navigationContext, breadcrumbs, viewSwitcher, isPowerUserPanel, isClosed, onNodeClick }) => {
    const location = useLocation();

    const activeNode = useMemo(() => {
        const getExtension = url => url.match(/\/modules\/([^/]+)/)?.[1];
        const breadcrumbPathname = parsePath(breadcrumbs?.[(isPowerUserPanel || breadcrumbs.length < 2) ? 0 : 1]?.href ?? '').pathname ?? '';
        const breadcrumbExtension = getExtension(breadcrumbPathname);
        const locationPathname = location.pathname;
        const locationExtension = getExtension(locationPathname);

        for (const group of navigation) {
            const foundNode = Object.entries(group.nodes).find(([, node]) => {
                const nodePathname = parsePath(node.link).pathname;
                if (!nodePathname) {
                    return false;
                }
                if (matchPath(nodePathname, breadcrumbPathname) || locationPathname.startsWith(nodePathname)) {
                    return true;
                }

                const nodeExtension = getExtension(nodePathname);
                if (!nodeExtension) {
                    return false;
                }
                return nodeExtension === breadcrumbExtension || nodeExtension === locationExtension;
            });

            if (foundNode) {
                return foundNode[0];
            }
        }
    }, [navigation, breadcrumbs, isPowerUserPanel, location.pathname]);

    return (
        <div
            className={classNames(
                'page-sidebar',
                isClosed && 'page-sidebar--folded',
            )}
            data-type="page-sidebar"
        >
            <div className="page-sidebar__content">
                <div className="page-sidebar-brand">
                    {isClosed ? (
                        <div className="brand-collapsed">{title[0] || 'P'}</div>
                    ) : (
                        <Brand href={getFirstLink(navigation)} />
                    )}
                </div>
                <div className="page-sidebar-menu-wrapper">
                    <ul className="page-sidebar-menu">
                        {navigation.map(({ id, ...props }) => (
                            <Group
                                key={id}
                                id={id}
                                isSidebarClosed={isClosed}
                                onNodeClick={onNodeClick}
                                activeNode={activeNode || navigationContext}
                                {...props}
                            />
                        ))}
                    </ul>
                </div>
            </div>
            {viewSwitcher ? (
                <div className="page-sidebar-footer-wrapper">
                    <div className="page-sidebar-footer">
                        {<ViewSwitcher {...viewSwitcher} />}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

PageSidebar.propTypes = {
    title: PropTypes.string.isRequired,
    logo: PropTypes.object,
    navigation: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        showTitle: PropTypes.bool,
        name: PropTypes.string,
        nodes: PropTypes.object,
    })).isRequired,
    navigationContext: PropTypes.string,
    breadcrumbs: PropTypes.array,
    viewSwitcher: PropTypes.shape({
        locale: PropTypes.object,
    }),
    isPowerUserPanel: PropTypes.bool,
    isClosed: PropTypes.bool,
    onNodeClick: PropTypes.func,
};

PageSidebar.defaultProps = {
    logo: undefined,
    navigationContext: undefined,
    breadcrumbs: [],
    viewSwitcher: undefined,
    isPowerUserPanel: false,
    isClosed: false,
    onNodeClick: undefined,
};

export default PageSidebar;
