// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { NotificationPropType } from './lib/PropTypes';
import { UAT } from 'jsw';

const handleClickButton = (code, messageKey) => {
    UAT.dispatchAction('NOTIFICATION_CTA', {
        type: code,
        messageKey,
    });
};

const NotificationCenterView = ({
    notification: { code, message, actionLink, actionText, openInNewTab, messageKey },
}) => (
    <>
        <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: message }}
        />
        {actionLink ? (
            <div className="notification-center-view__actions">
                <Button
                    data-type={code}
                    component="a"
                    intent="primary"
                    size="lg"
                    href={actionLink}
                    target={openInNewTab ? '_blank' : null}
                    rel={openInNewTab ? 'noopener noreferrer' : null}
                    onClick={() => handleClickButton(code, messageKey)}
                >
                    {actionText}
                </Button>
            </div>
        ) : null}
    </>
);

NotificationCenterView.propTypes = {
    notification: NotificationPropType.isRequired,
};

export default NotificationCenterView;
