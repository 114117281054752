// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import prepareUrl from './prepareUrl';

export default (id, options = {}) => {
    options = {
        openInNew: false,
        label: '',
        hint: '',
        returnUrl: '',
        isClassicUi: '',
        showAll: false,
        ...options,
    };

    if (options.isClassicUi) {
        options.returnUrl = `/subscription/overview/id/${id}`;
    }
    let url = prepareUrl(`/subscription/login/id/${id}`);

    if (options.showAll) {
        url += '/all/true';
    }

    if (options.returnUrl) {
        url += `/?returnUrl=${prepareUrl(options.returnUrl)}`;
    }

    return (
        '<span>' +
            `<span class="tooltipData">${options.hint}</span>` +
            `<a class="s-btn sb-login" href="${url}"` +
                `${options.isClassicUi ? '' : ` target="${options.openInNew ? '_blank' : '_top'}"`}>` +
                `<i><i><i><span>${options.label}</span></i></i></i>` +
            '</a>' +
        '</span>'
    );
};
