// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useMemo } from 'react';
import { Outlet, useLoaderData, useMatches } from 'react-router-dom';
import { Locale } from 'jsw';
import PageLayout from './PageLayout';

const HELP_CONTEXT_MAP = {
    'server/about': 'about.index',
    'server/action-log': 'actionlog',
    'server/settings': 'preferences',
};

const CpPagesLayout = () => {
    const { showFrames, pageHeader, pageSidebar } = useLoaderData();
    const matches = useMatches();

    const breadcrumbs = useMemo(() => {
        const routesLocale = Locale.getSection('components.routes');

        return matches.reduce((breadcrumbs, { pathname }) => {
            const pathnameMatch = pathname?.match(/^\/cp\/(.+?)\/?$/);
            if (pathnameMatch) {
                return [...breadcrumbs, {
                    title: routesLocale.lmsg(`${pathnameMatch[1]}.title`),
                    href: pathnameMatch[1] === 'server' ? '/admin/server/tools' : pathname,
                }];
            }

            return breadcrumbs;
        }, pageSidebar.isPowerUserPanel ? [] : [{
            title: routesLocale.lmsg('home.title'),
            href: '/admin/home',
        }]);
    }, [matches, pageSidebar.isPowerUserPanel]);

    const helpContext = useMemo(() => {
        const pathnameMath = matches[matches.length - 1]?.pathname?.match(/^\/cp\/(.+?)\/?$/);
        if (pathnameMath) {
            return HELP_CONTEXT_MAP[pathnameMath[1]];
        }
    }, [matches]);

    const pageTitle = breadcrumbs[breadcrumbs.length - 1]?.title;

    const data = {
        showFrames,
        pageHeader: {
            ...pageHeader,
            helpMenu: {
                ...pageHeader.helpMenu,
                helpContext,
            },
        },
        pageSidebar,
        pageContentHeader: {
            pageTitle,
            breadcrumbs,
        },
    };

    return (
        <PageLayout {...data}>
            <Outlet />
        </PageLayout>
    );
};

export default CpPagesLayout;
