// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import render from './render';
import emptyFn from './emptyFn';
import { ESC } from './keyCode';

import './modal-dialog-box.less';

export class ModalDialogBox extends Component {
    constructor(config) {
        super(config);
        this._componentElement.querySelectorAll('[autofocus]').forEach(el => el.focus());
    }

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._screenDisablerId = this._getConfigParam('screenDisablerId', 'screenDisabler');
        this._hideOnEscape = this._getConfigParam('hideOnEscape', false);
        this._hideOnBgClick = this._getConfigParam('hideOnBgClick', true);
        this._onShow = this._getConfigParam('onShow', emptyFn);
        this._onHide = this._getConfigParam('onHide', emptyFn);

        if (!document.getElementById(this._screenDisablerId)) {
            ModalDialogBox.initScrollbarSpacer();
            render(
                document.body,
                `<div id="${this._screenDisablerId}" class="main-disabled-block"></div>`,
                'top',
            );
        }

        this._screenDisabler = document.getElementById(this._screenDisablerId);

        const renderTo = this._getConfigParam('renderTo', 'modalDialogBox');
        this._renderTarget = typeof renderTo === 'string' ? document.getElementById(renderTo) : renderTo;
        if (!this._renderTarget) {
            this._renderTarget = document.createElement('div');
            this._renderTarget.id = renderTo;
        }

        this._renderTarget.innerHTML = '';
        render(this._screenDisabler, this._renderTarget, 'after');
        this._onKeyDownhandler = this._onKeyDown.bind(this);
    }

    _addEvents() {
        super._addEvents();

        if (this._hideOnBgClick) {
            this._componentElement.addEventListener('click', () => {
                this.hide();
            });
        }

        const popupContainer = this._componentElement.querySelector('.popup-container');
        if (popupContainer) {
            popupContainer.addEventListener('click', event => {
                event.stopPropagation();
            });
        }

        this._onKeyDownhandler = this._onKeyDown.bind(this);
    }

    show() {
        super.show();
        document.querySelector('html').classList.add('modal-open');
        document.addEventListener('keydown', this._onKeyDownhandler);
        this._onShow();
    }

    hide() {
        super.hide();
        document.querySelector('html').classList.remove('modal-open');
        document.removeEventListener('keydown', this._onKeyDownhandler);
        this._onHide();
    }

    _onKeyDown(e) {
        switch (e.keyCode) {
            case (this._hideOnEscape && ESC):
                this.hide();
                break;
        }
    }
}

ModalDialogBox.initScrollbarSpacer = () => {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.width = '50px';
    scrollDiv.style.height = '50px';
    scrollDiv.style.overflow = 'scroll';
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    document.body.appendChild(scrollDiv);

    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);

    const styleEl = document.createElement('style');
    document.head.appendChild(styleEl);
    styleEl.sheet.insertRule(`.modal-open {margin-right: ${scrollbarWidth}px}`, 0);
    styleEl.sheet.insertRule(`.modal-open .js-scrollbar-spacer {margin-right: ${scrollbarWidth}px}`, 1);
};
