// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Button, Text, Icon, Alert, Media, MediaSection, Translate } from '@plesk/ui-library';

const RepaitKitAlert = () => (
    <Alert intent="info">
        <Media image={<Icon src="/error_docs/images/go-to-repair.png" size="64" />}>
            {window.location.pathname.indexOf('/repair') === 0 ? (
                <MediaSection>
                    <Translate content="repairKitUnavailable" />
                </MediaSection>
            ) : (
                <>
                    <MediaSection>
                        <Translate content="repairKitDescription" />
                        <br />
                        <Translate content="repairKitAdminDescription" />
                    </MediaSection>
                    <MediaSection>
                        <Button component="a" intent="primary" href="/repair">
                            <Translate content="repairKitOpen" />
                        </Button>
                    </MediaSection>
                    <MediaSection>
                        <Text intent="muted"><Translate content="repairKitOpenNote" /></Text>
                    </MediaSection>
                </>
            )}
        </Media>
    </Alert>
);

export default RepaitKitAlert;
