// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Heading, Paragraph, Link, FormFieldRadioButtons } from '@plesk/ui-library';
import { Locale } from 'jsw';
import DrawerAdapter from './DrawerAdapter';
import { PLESK_COOKIES, CONTROL_COOKIES } from '../urls';

const Translate = Locale.getTranslate('components.cookie-policy.preferences');

const toValues = preferences => Object.keys(preferences).reduce((acc, k) => {
    acc[k] = preferences[k] ? 'on' : 'off';
    return acc;
}, {});

const toPreferences = values => Object.keys(values).reduce((acc, k) => {
    acc[k] = values[k] === 'on';
    return acc;
}, {});

const Preferences = ({ isOpen, onSave, onToggle, preferences, adapter: Adapter = DrawerAdapter }) => {
    const values = useMemo(() => toValues(preferences), [preferences]);

    const handleSubmit = values => {
        onSave(toPreferences(values));
        onToggle(false);
    };

    const handleCancel = () => onToggle(false);

    return (
        <Adapter
            isOpen={isOpen}
            title={<Translate content="title" />}
            submitButton={{
                'data-type': 'cookie-policy-preferences-submit-button',
                'data-testid': 'submitButton',
                children: (
                    <Translate content="submitButton" />
                ),
            }}
            cancelButton={{
                'data-type': 'cookie-policy-preferences-cancel-button',
                'data-testid': 'cancelButton',
                children: (
                    <Translate content="cancelButton" />
                ),
                onClick: handleCancel,
            }}
            values={values}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        >
            <Paragraph>
                <Translate content="description" />
            </Paragraph>
            <Heading level={2}>
                <Translate content="settingsTitle" />
            </Heading>
            <Paragraph>
                <Translate content="settingsDescription" />
            </Paragraph>
            <Heading level={3}>
                <Translate content="analyticsTitle" />
            </Heading>
            <Paragraph>
                <Translate content="analyticsDescription" />
            </Paragraph>
            <FormFieldRadioButtons
                name="analytics"
                value={values.analytics}
                className="cookie-policy-preferences__radio-buttons"
                data-testid={`analytics${values.analytics}`}
                options={[
                    { value: 'on', label: <Translate content="on" /> },
                    { value: 'off', label: <Translate content="off" /> },
                ]}
            />
            <Heading level={3}>
                <Translate content="marketingTitle" />
            </Heading>
            <Paragraph>
                <Translate content="marketingDescription" />
            </Paragraph>
            <FormFieldRadioButtons
                name="marketing"
                value={values.marketing}
                className="cookie-policy-preferences__radio-buttons"
                options={[
                    { value: 'on', label: <Translate content="on" /> },
                    { value: 'off', label: <Translate content="off" /> },
                ]}
            />
            <Heading level={3}>
                <Translate content="necessaryTitle" />
            </Heading>
            <Paragraph>
                <Translate
                    content="necessaryDescription"
                    params={{
                        browserSettingsLink: (
                            <Link href={CONTROL_COOKIES} target="_blank">
                                <Translate content="browserSettingsLink" />
                            </Link>
                        ),
                    }}
                />
            </Paragraph>
            <Paragraph>
                <Link href={PLESK_COOKIES} target="_blank">
                    <Translate content="pleskCookiesLink" />
                </Link>
            </Paragraph>
        </Adapter>
    );
};

Preferences.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    preferences: PropTypes.object.isRequired,
    adapter: PropTypes.func,
};

Preferences.defaultProps = {
    adapter: undefined,
};

export default Preferences;
