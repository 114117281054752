// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import apolloClient from '../apolloClient';

import HOSTING_SETTINGS_PAGE from 'queries/HostingSettingsPage.graphql';

export default [
    {
        path: 'hosting-settings',
        componentLoader: () => import(/* webpackChunkName: "components/pages/HostingSettingsPage" */'components/pages/HostingSettingsPage'),
        loader: async ({ params }) => {
            await apolloClient.query({
                query: HOSTING_SETTINGS_PAGE,
                variables: { id: btoa(`Domain:${params.id}`) },
            });
        },
    },
];
