// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

/* eslint-disable camelcase */

/**
 * @deprecated
 */
export const chk_pd_name = (dir, isWin) => {
    const re = isWin ? /^[0-9a-zA-Z\-_.\/~@!\+=\^\(\)\[\]\{\}, ]+$/ : /^[0-9a-zA-Z-_.\/]+$/;
    const re1 = isWin
        // forbid // and ^. and /. and .$ and ./
        ? /(\/\/|^\.|\.$|\/\.|\.\/|\/ | \/)/
        // forbid /../ and // and ^./ and ^../  and /./  and /..$ and /.$ and ^.$
        : /(\/\.\.\/|\/\/|^\.\/|^\.\.\/|\/\.\/|\/\.\.$|\/\.$|^\.$)/;
    return (dir.search(re) !== -1) && (dir.search(re1) === -1);
};

/**
 * @deprecated
 */
export const chk_pos_int = pos_int => {
    const re = /^\s*[1-9]{1}[0-9]*\s*$/;
    return pos_int.search(re) !== -1;
};

/**
 * @deprecated
 */
export const chk_mn = (mail_name, isWin) => {
    const re = isWin ? /^([A-Za-z0-9_\-])+((\.){1}([A-Za-z0-9_\-])+)*$/ : /^[\w-][\w&\-\+]*((\.)[\w&\-\+]+)*$/;
    return mail_name.search(re) !== -1;
};

/**
 * @deprecated
 */
export const chk_login = login => login.search(/^[^\s]{1,255}$/) !== -1;

/**
 * @deprecated
 */
export const chk_realm = realm => realm.search(/^[^\"]*$/) !== -1;

/**
 * @deprecated
 */
export const chk_dom = (dom_name, isWin) => {
    // check empty value
    if ('' === dom_name) {
        return false;
    }

    if ('localhost.rev' === dom_name) {
        return false;
    }

    const inaddr = /\.in-addr.arpa$/;
    if (dom_name.search(inaddr) !== -1) {
        return false;
    }

    // no domain name like IP address
    if (chk_ip_address_and_mask(dom_name, 'IP_ADDRESS', 'IP_ADDRESS_FORMAT')) {
        return false;
    }

    if (isWin && dom_name.search(/\.d$/) !== -1) {
        return false;
    }

    return true;
};

/**
 * @deprecated
 */
export const chk_email = (email, isWin) => {
    const re = /^([^\@]+){1}\@([^\@]+){1}$/;
    const found = email.match(re);
    if (!found) {
        return false;
    }
    return chk_mn(found[1], isWin) && chk_dom(found[2], isWin);
};

/**
 * @deprecated
 */
export const chk_url = url => {
    const re = /^((http[s]?|ftp):\/\/)?([^\/:]+)(:\d{1,5})?(\/[^\s\"\'`]*)?$/i;
    const found = re.exec(url);
    if (!found) {
        return false;
    }

    return chk_dom(found[3]);
};

/**
 * @deprecated
 */
export const chk_ip_t = ip => {
    const re = /^<ip>$/;
    return chk_ip_address_and_mask(ip, 'IP_ADDRESS', 'IP_ADDRESS_FORMAT') || ip.match(re);
};

/**
 * @deprecated
 */
export const chk_mask = mask => {
    const re = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
    const found = mask.match(re);
    if (!found) {
        return false;
    }
    let i = found.length - 1;
    while (i && (found[i] === 0)) {
        i--;
    }
    if (!i) {
        return true;
    }
    if (
        (found[i] !== 128) &&
        (found[i] !== 192) &&
        (found[i] !== 224) &&
        (found[i] !== 240) &&
        (found[i] !== 248) &&
        (found[i] !== 252) &&
        (found[i] !== 254) &&
        (found[i] !== 255)
    ) {
        return false;
    }

    i--;
    while (i && (found[i] === 255)) {
        i--;
    }

    return !i;
};

/**
 * @deprecated
 */
export const ip2long = str => {
    let num = 0;
    const re = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;

    const found = str.match(re);
    if (!found) {
        return null;
    }
    for (let i = 1; i < found.length; i++) {
        num <<= 8;
        num |= found[i];
    }
    return num;
};

/**
 * convert ip block mask to int32 mask
 * @deprecated
 */
export const block_to_num = block => {
    let num = 0;
    for (let i = 0; i < 32; i++) {
        num <<= 1;
        num |= (block > i ? 1 : 0);
    }
    return num;
};

/**
 * check ip address and mask by valid rules
 * @deprecated
 */
export const chk_ip_address_and_mask = (ip, valid, valid_formats) => {
    const ip_address_block_format = /^(\d{1,3})(\.(\d{1,3})(\.(\d{1,3})(\.(\d{1,3})|)|)|)\/(\d{1,2})$/;
    const ip_address_format = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;

    let found;
    let ip_address = null;
    let ip_mask = null;

    if (
        (valid_formats === 'IP_ADDRESS_BLOCK_FORMAT') &&
        (found = ip.match(ip_address_block_format)) &&
        (!found[1] || (found[1] >= 0 && found[1] <= 255)) &&
        (!found[3] || (found[3] >= 0 && found[3] <= 255)) &&
        (!found[5] || (found[5] >= 0 && found[5] <= 255)) &&
        (!found[7] || (found[7] >= 0 && found[7] <= 255)) &&
        (found[8] >= 0 && found[8] <= 32)
    ) {
        ip_address = ip2long(`${found[1] ? found[1] : 0}.${found[3] ? found[3] : 0}.${found[5] ? found[5] : 0}.${found[7] ? found[7] : 0}`);
        ip_mask = block_to_num(found[8]);
    } else if (
        (valid_formats === 'IP_ADDRESS_FORMAT') &&
        (found = ip.match(ip_address_format)) &&
        (found[1] >= 0 && found[1] <= 255) &&
        (found[2] >= 0 && found[2] <= 255) &&
        (found[3] >= 0 && found[3] <= 255) &&
        (found[4] >= 0 && found[4] <= 255)
    ) {
        ip_address = ip2long(`${found[1]}.${found[2]}.${found[3]}.${found[4]}`);
        ip_mask = block_to_num(32);
    }

    if (null === ip_address || null === ip_mask) {
        return false;
    }

    return (
        // ip address with netmask
        ((valid === 'IP_ADDRESS_NETMASK') &&
            ip_address && ip_mask &&
                (
                    // and not empty lower bits
                    ((ip_address & ~ip_mask) &&
                    // minimum network size 'may be removed - bcause added single addres networks by client request ...
                    ~((ip_address & ~ip_mask) | ip_mask)
                    ) || (ip_mask === block_to_num(32))
                )
        ) ||
        // sigle ip address
        ((valid === 'IP_ADDRESS') &&
            // not empty ip address and 0xFFFFFFFF ip mask
            ip_address && (ip_mask === block_to_num(32))
        )
    );
};

/**
 * @deprecated
 */
export const chk_sys_passwd = (username, passwd, minLen, maxLen) => {
    if ((passwd.length < minLen) || (passwd.length > maxLen)) {
        return false;
    }

    if (passwd.length >= username.length) {
        if (passwd.indexOf(username, 0) !== -1) {
            return false;
        }
    }

    if ((passwd.indexOf('\'') !== -1) || (passwd.indexOf(' ') !== -1)) {
        return false;
    }

    for (let i = 0; i < passwd.length; i++) {
        if (passwd.charCodeAt(i) > 127) {
            return false;
        }
    }

    return true;
};

/**
 * @deprecated
 */
export const chk_uint = uint => !!uint.match(/^[0-9]+$/);
