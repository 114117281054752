// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * Helper for global modals like Notifications and Cookies
 */
const useModal = (id, search) => {
    const key = `modals[${id}]`;
    const navigate = useNavigate();
    const location = useLocation();
    const value = new URLSearchParams(search ?? location.search).get(key);

    const setValue = useCallback(next => {
        const params = new URLSearchParams(window.location.search);
        if (next) {
            params.set(key, next);
            navigate(`?${params}`);
        } else if (params.has(key)) {
            params.delete(key);
            navigate(`?${params}`);
        }
    }, [key, navigate]);

    return [value, setValue];
};

export default useModal;
