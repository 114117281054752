// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Component } from './component';

export class CommandButton extends Component {
    _initConfiguration(config) {
        super._initConfiguration({ tag: 'button', ...config });

        this._handler = this._getConfigParam('handler', false);
        if (typeof this._handler === 'string') {
            // eslint-disable-next-line no-eval
            eval(`this._handler = ${this._handler}`);
        }

        this._value = this._config.value || '';
        this._tabIndex = this._getConfigParam('tabIndex', null);
    }

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement.type = 'button';
        this._componentElement.value = this._value;
        this._componentElement.name = this._config.name;
        this._componentElement.innerHTML = this._config.title;

        if (null !== this._tabIndex) {
            this._componentElement.setAttribute('tabindex', this._tabIndex);
        }

        this._initVisibility();
    }

    _initVisibility() {
        this._disabled = this._getConfigParam('disabled', false);

        if (this._disabled) {
            this._disabled = false;
            this.disable();
        } else {
            this._disabled = true;
            this.enable();
        }
    }

    setHandler(handler) {
        if (this._handler && !this._disabled) {
            this.removeEventObserver('click', this._handler);
        }

        this._handler = handler;
        if (!this._disabled) {
            this.addEventObserver('click', this._handler);
        }
    }

    enable() {
        if (!this._disabled) {
            return;
        }

        this._disabled = false;
        if ('send' === this._config.name || 'apply' === this._config.name) {
            this._componentElement.classList.add('action');
        }
        this._componentElement.classList.remove('disabled');
        this._componentElement.disabled = false;
        if (this._handler) {
            this.addEventObserver('click', this._handler);
        }
    }

    disable() {
        if (this._disabled) {
            return;
        }

        this._disabled = true;
        this._componentElement.disabled = true;
        if ('send' === this._config.name || 'apply' === this._config.name) {
            this._componentElement.classList.remove('action');
        }
        this._componentElement.classList.add('disabled');
        if (this._handler) {
            this.removeEventObserver('click', this._handler);
        }
    }
}
