// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, disableDocumentScroll, enableDocumentScroll } from '@plesk/ui-library';

const hasScroll = () => document.documentElement.style.position !== 'fixed';

const disableScroll = () => {
    if (hasScroll()) {
        disableDocumentScroll();
    }
};

const enableScroll = () => {
    if (!hasScroll()) {
        enableDocumentScroll();
    }
};

const Overlay = ({ isOpen, children, onCancel }) => {
    const enterTimeoutRef = useRef(null);
    const exitTimeoutRef = useRef(null);
    const [isReady, setReady] = useState(isOpen);

    useEffect(() => {
        let listener;
        if (isOpen) {
            setReady(true);
            listener = e => {
                if (e.keyCode === 27) {
                    onCancel();
                }
            };
            document.addEventListener('keydown', listener);
            enterTimeoutRef.current = setTimeout(() => {
                disableScroll();
            }, 500);
        } else {
            exitTimeoutRef.current = setTimeout(() => {
                setReady(false);
                enableScroll();
            }, 500);
        }
        return () => {
            clearTimeout(enterTimeoutRef.current);
            clearTimeout(exitTimeoutRef.current);
            enableScroll();
            document.removeEventListener('keydown', listener);
        };
    }, [isOpen, onCancel]);

    if (!isOpen && !isReady) {
        return null;
    }

    return createPortal(
        <div
            className={classNames('cookie-policy-overlay', {
                'cookie-policy-overlay--enter': isOpen && isReady,
                'cookie-policy-overlay--exit': !isOpen && isReady,
            })}
            data-testid="overlayContainer"
        >
            <div className="cookie-policy-overlay__modal">
                <a onClick={onCancel} className="cookie-policy-overlay__modal-close">
                    <Icon name="cross-mark" />
                </a>
                <div className="cookie-policy-overlay__modal-content">
                    <div className="cookie-policy-overlay__modal-inner">{children}</div>
                </div>
            </div>
        </div>,
        document.body,
    );
};

Overlay.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default Overlay;
