// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import apolloClient from 'apolloClient';

import ODBC_ADD_PAGE from 'queries/OdbcAddPage.graphql';

export const odbcAddRoute = {
    path: 'add',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/OdbcAddPage" */
        'components/pages/OdbcAddPage'
    ),
    loader: async ({ params }) => {
        const { data: { dsnManager: { drivers } } } = await apolloClient.query({
            query: ODBC_ADD_PAGE,
        });

        return { domainId: params.domainId, drivers };
    },
};
