// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export default url => {
    if (typeof url !== 'string') {
        return null;
    }

    const baseUrl = (global.Jsw && global.Jsw.baseUrl) || '';

    if (url.substr(0, 2) === '^/') {
        return url.toString().substr(1);
    }

    if (
        url.startsWith('/plesk/') ||
        url.startsWith('/services/') ||
        url.startsWith('/modules/') ||
        url.startsWith('/admin/') ||
        url.startsWith('/smb/') ||
        url.startsWith('/cp/') ||
        (baseUrl !== '' && url.startsWith(`${baseUrl}/`)) ||
        !url.startsWith('/') ||
        url.split('?')[0].endsWith('.php')
    ) {
        return url;
    }

    return baseUrl + url;
};
